.container {
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}

.presentation {
  display: flex;
  flex: 1;
}

.footer { 
  display: flex;
  /*transition: height 0.4s;*/
}

.panel { 
  display: flex;
  /*transition: width 0.4s;*/
  overflow: hidden;
}

.footerContent {
  display: flex;
  flex: 1;
  background-color: black;
}

.panelContent {
  display: flex;
  flex: 1;
  background-color: black;
}