.baseFont {
  color: #333333;
  font-size: 16px;
  font-family: 'PT Sans', sans-serif;
}

.panelContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white; 
}

.panelContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 4px;
  min-height: 48px;
  background-color: #f8f8f8;
  border-color: #e7e7e7;
  border-width: 0 0 1px 0;
  border-style: solid;
  position: relative;
}

.headerLogo {
  width: 30px;
  height: 30px;
  background-size: 30px;
}

.headerTitle {
  flex: 1;
  font-size: 20px;
}

.headerTime {
  font-size: 20px;
  font-weight: bold;
  margin-right: 4px;
}

.headerNttbLogo {
  width: 48px;
  height: 48px;
  background-size: 48px;
}

.headerProgressBar {
  display: flex;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 2px;
}

.progressBarContainer {
  display: flex;
  flex: 1;
  background-color: #d9edf7;
}

.progressBar {
  display: flex;
  flex: 1;
  background-color: #31708f;
}

.footerContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  min-height: 48px;
  align-items: center;
  background-color: #000000;
  color: #ffffff;
  overflow: hidden;
  font-size: 26px;
  font-weight: bold;
}

.footerLive {
  background-color: #f21818;
}

.footerText {
  padding: 0 16px;
}

.footerTextSmall {
  padding: 0 8px 0 16px;
  font-size: 19px;
  opacity: 0.7;
}

.footerTicker {
  display: flex;
  flex: 1;
  margin-left: 24px; 
}

.footerTickerItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 160px 0 16px;
}

.footerTickerScore {
  margin-left: 8px;
  background-color: #ffffff;
  color: #f21818;
  border-radius: 16px;
  padding: 0 12px;
}

.groupHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  background-color: #f0f8ff;
  border-color: #d9edf7;
  border-width: 0 0 1px 0;
  border-style: solid;
}

.groupHeaderText {
  font-size: 90%;
  font-weight: bold;
  color: #31708f;
}

.matchItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
  border-color: #e7e7e7;
  border-width: 0 0 1px 0;
  border-style: solid;
}

.matchItemColumn { 
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.matchItemRightColumn { 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.matchItemHeader { 
  font-size: 80%;
  color: #888;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.matchItemLive { 
  background-color: #f21818;
  color: white;
  margin-left: 8px;
  font-size: 10px;
  font-weight: bold;
  padding: 2px 4px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 4px;
}

.matchItemBody { 
  font-size: 100%;
}

.matchItemHomeTeam { 
  font-weight: bold;
}

.matchItemScore {
  font-size: 120%;
  font-weight: bold;
}

.multiMatchContainer {
  position: relative;
  overflow: hidden;
}

.multiMatchItem {
  transition: transform 0.6s ease-in-out;
}

.multiMatchAbsoluteItem {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
