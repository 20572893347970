.flex {
  display: flex;
  flex: 1;
}

.googleSlideContainer {
  display: flex;
  width: 100%;
  height: calc(100% + 36px);
}

.googleSlideIFrame {
  display: flex;
  flex: 1;
  border: 0;
  padding: 0;
  margin: 0;
}
