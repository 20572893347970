.container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.logo {
  width: 200px;
  height: 200px;
}

.container a {
  margin: 0 0 6px 0;
}
